import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"

const StyledDontWorryContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  grid-column-gap: 30px;
  padding: 30px 15px;
  background-color: rgba(0, 143, 170, 0.1);
  margin-top: 80px;
  z-index: -3;

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, auto);
  }
  @media (max-width: 450px) {
    grid-column-gap: 0;
  }
  @media (max-width: 350px) {
    grid-template-columns: auto;
  }
`
const StyledNotWorryContent = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(47, 57, 78, 1);
  justify-content: center;
  @media (max-width: 767px) {
    grid-column: 1 / span 2;
  }
  @media (max-width: 350px) {
    grid-column: 1 / span 1;
  }
`
export const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 1020px);
  justify-content: center;
  text-align: center;
`

const StyledNotWorryContentTitle = styled.p`
  font-size: 36px;
  margin-bottom: 10px;
`
const StyledNotWorryContentText = styled.p`
  font-size: 18px;
  color: #3d4d69;
`

const DontWorryComp = ({ data }) => (
  <StyledDontWorryContainer>
    <div>
      <GatsbyImage image={data.fourteenDays.childImageSharp.gatsbyImageData} />
    </div>
    <div>
      <GatsbyImage image={data.moneyback.childImageSharp.gatsbyImageData} />
    </div>
    <StyledNotWorryContent>
      <StyledNotWorryContentTitle>Do not worry</StyledNotWorryContentTitle>
      <StyledNotWorryContentText>
        You have 14 days of money back guarantee
      </StyledNotWorryContentText>
    </StyledNotWorryContent>
  </StyledDontWorryContainer>
)

export const DontWorry = props => (
  <StaticQuery
    query={graphql`{
  fourteenDays: file(relativePath: {eq: "free_trial_1.png"}) {
    childImageSharp {
      gatsbyImageData(quality:100, width: 170, height: 170, layout: FIXED)
    }
  }
  moneyback: file(relativePath: {eq: "moneyback_sticker.png"}) {
    childImageSharp {
      gatsbyImageData(quality:100, width: 170, height: 170, layout: FIXED)
    }
  }
}
`}
    render={data => <DontWorryComp data={data} {...props} />}
  />
)

