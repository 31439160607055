import React from "react"
import Title from "../components/title"
import { DontWorry, TitleContainer } from "../components/pricing"
import { CardContainer } from "../components/card"
import { PricingAccordion } from "../components/Accordion"
import SEO from "../components/SEO/seo"

export default ({ location }) => (
  <>
    <SEO
      title="Groupboss Pricing : Cost effecitive plans for everyone"
      description="Accelerate your business by collecting unlimited leads from your Facebook groups with Groupboss. It offers three affordable plans for users."
      pathname={location.pathname}
    />
    <TitleContainer>
      <Title
        titleText="Accelerate your business by collecting unlimited leads from your Facebook groups."
        titleSub="Use Groupboss with your Facebook group and collect unlimited leads."
        main
      />
    </TitleContainer>
    {/* <PricingTimeTitle /> */}
    <CardContainer />
    <DontWorry />
    <PricingAccordion />
  </>
)
